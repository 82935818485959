export * from '@oracle-cx-commerce/react-widgets';

export const FUGINIFooterPayments = () => import('./FUGINI/footer/fugini-footer-payments');
export const FUGINICheckoutShippingInformation = () => import('./FUGINI/checkout/fugini-checkout-shipping-information');
export const FUGINICheckoutContinueToPaymentButton = () =>
  import('./FUGINI/checkout/fugini-checkout-continue-to-payment-button');
export const FUGINICheckoutScheduled = () => import('./FUGINI/checkout/fugini-checkout-scheduled');
export const FUGINICheckoutShippingOptimization = () =>
  import('./FUGINI/checkout/fugini-checkout-shipping-optimization');
export const FUGINIFooterContact = () => import('./FUGINI/footer/fugini-footer-contact');
export const FUGINIProductListingSortResults = () =>
  import('./FUGINI/product-listing/fugini-product-listing-sort-results');
export const FUGINIProductListingFastPurchase = () => import('./FUGINI/product-listing/fugini-product-listing-fast-purchase');
export const FUGINIProductResultPrice = () => import('./FUGINI/product-listing/fugini-product-result-price');
export const FUGINIProductPrice = () => import('./FUGINI/product/fugini-product-price');
export const FUGINIFooterBottom = () => import('./FUGINI/footer/fugini-footer-bottom');
export const FUGINICheckoutBill = () => import('./FUGINI/checkout/fugini-checkout-bill');
export const FUGINICheckoutCreditCard = () => import('./FUGINI/checkout/fugini-checkout-credit-card');
export const FUGINICheckoutPaymentPix = () => import('./FUGINI/checkout/fugini-checkout-payment-pix');
export const FUGINICheckoutBillInvoiced = () => import('./FUGINI/checkout/fugini-checkout-bill-invoiced');
export const FUGINICheckoutPaymentsContainer = () => import('./FUGINI/checkout/fugini-checkout-payments-container');
export const RAMYCheckoutBillInvoiced = () => import('./RAMY/checkout/ramy-checkout-bill-invoiced');
export const FUGINICheckoutFooter = () => import('./FUGINI/footer/fugini-checkout-footer');
export const FUGINIErrorFooter = () => import('./FUGINI/footer/fugini-error-footer');
export const FUGINICartShippingOptimization = () => import('./FUGINI/cart/fugini-cart-shipping-optimization');
export const FUGINICartItemDetails = () => import('./FUGINI/cart/fugini-cart-item-details');
export const FUGINICheckoutSingleShippingDetails = () =>
  import('./FUGINI/checkout/fugini-checkout-single-shipping-details');
export const FUGINIProfileAddToPurchaseList = () => import('./FUGINI/profile/fugini-profile-add-to-purchase-list');
export const FUGINIProductAddToCartButton = () => import('./FUGINI/product/fugini-product-add-to-cart-button');
export const FUGINIProductInventoryStatus = () => import('./FUGINI/product/fugini-product-inventory-status');
export const FUGINIProductQuantity = () => import('./FUGINI/product/fugini-product-quantity');
export const FUGINIHeaderMiniCartDesktop = () => import('./FUGINI/header/fugini-header-mini-cart/desktop');
export const FUGINIHeaderMiniCartMobile = () => import('./FUGINI/header/fugini-header-mini-cart/mobile');
export const FUGINICartOrderSummary = () => import('./FUGINI/cart/fugini-cart-order-summary');
export const FUGINICommonSearchBoxMobile = () => import('./FUGINI/common/fugini-common-search-box-mobile');
export const FUGINICartContainer = () => import('./FUGINI/cart/fugini-cart-container');
export const FUGINIProductListingDynamicProductList = () =>
  import('./FUGINI/product-listing/fugini-product-listing-dynamic-product-list');
export const FUGINICheckoutButton = () => import('./FUGINI/checkout/fugini-checkout-button');

export const RAMYCartItemDetails = () => import('./RAMY/cart/ramy-cart-item-details');
export const RAMYCartOrderSummary = () => import('./RAMY/cart/ramy-cart-order-summary');
export const RAMYCartPromotionCodeEntry = () => import('./RAMY/cart/ramy-cart-promotion-code-entry');
export const RAMYCartResumeContainer = () => import('./RAMY/cart/ramy-cart-resume-container');
export const RAMYCartCouponContainer = () => import('./RAMY/cart/ramy-cart-coupon-container');
export const RAMYCartPromotionsDisplay = () => import('./RAMY/cart/ramy-cart-promotions-display');
export const RAMYCommonProductRecommendationsCarousel = () =>
  import('./RAMY/common/ramy-common-product-recommendations-carousel');
export const RAMYAccountsContactsRegistrationContainer = () =>
  import('./RAMY/profile/ramy-accounts-contacts-registration-container');
export const RAMYAccountsContactsRegistration = () => import('./RAMY/profile/ramy-accounts-contacts-registration');
export const RAMYCartShippingOptimization = () => import('./RAMY/cart/ramy-cart-shipping-optimization');
export const RAMYProductAddToCartButton = () => import('./RAMY/product/ramy-product-add-to-cart-button');
export const RAMYProductPrice = () => import('./RAMY/product/ramy-product-price');
export const RAMYProductProductLongDescription = () => import('./RAMY/product/ramy-product-product-long-description');
export const RAMYProfileAddToPurchaseList = () => import('./RAMY/profile/ramy-profile-add-to-purchase-list');
export const RAMYProfileCreatePurchaseList = () => import('./RAMY/profile/ramy-profile-create-purchase-list');
export const RAMYProfileOrderDetailsContainer = () => import('./RAMY/profile/ramy-profile-order-details-container');
export const RAMYProfileOrderInformation = () => import('./RAMY/profile/ramy-profile-order-information');
export const RAMYProfilePurchaseListInformation = () => import('./RAMY/profile/ramy-profile-purchase-list-information');
export const RAMYProductQuantity = () => import('./RAMY/product/ramy-product-quantity');
export const RAMYProductListingFastPurchase = () => import('./RAMY/product-listing/ramy-product-listing-fast-purchase');

export const RAMYProductInventoryStatus = () => import('./RAMY/product/ramy-product-inventory-status');
export const RAMYCheckoutSingleShippingDetails = () => import('./RAMY/checkout/ramy-checkout-single-shipping-details');
export const RAMYCheckoutPaymentsContainer = () => import('./RAMY/checkout/ramy-checkout-payments-container');
export const RAMYCheckoutCreditCard = () => import('./RAMY/checkout/ramy-checkout-credit-card');
export const RAMYCheckoutBill = () => import('./RAMY/checkout/ramy-checkout-bill');
export const RAMYCheckoutPaymentPix = () => import('./RAMY/checkout/ramy-checkout-payment-pix');
export const RAMYCheckoutFooter = () => import('./RAMY/footer/ramy-checkout-footer');
export const RAMYContinueShoppingButton = () => import('./RAMY/common/ramy-continue-shopping-button');
export const RAMYCheckoutContinueToReviewOrderButton = () =>
  import('./RAMY/checkout/ramy-checkout-continue-to-review-order-button');
export const FUGINICheckoutContinueToReviewOrderButton = () =>
  import('./FUGINI/checkout/fugini-checkout-continue-to-review-order-button');
export const RAMYCheckoutShippingInformation = () => import('./RAMY/checkout/ramy-checkout-shipping-information');
export const RAMYCheckoutReviewOrderPaymentInformation = () =>
  import('./RAMY/checkout/ramy-checkout-review-order-payment-information');
export const RAMYCheckoutScheduled = () => import('./RAMY/checkout/ramy-checkout-scheduled');
export const RAMYCheckoutReviewOrderContainer = () => import('./RAMY/checkout/ramy-checkout-review-order-container');
export const RAMYCheckoutShippingContainer = () => import('./RAMY/checkout/ramy-checkout-shipping-container');
export const RAMYCheckoutOrderConfirmation = () => import('./RAMY/checkout/ramy-checkout-order-confirmation');
export const RAMYCheckoutContinueToPaymentButton = () =>
  import('./RAMY/checkout/ramy-checkout-continue-to-payment-button');
export const RAMYCheckoutShippingOptimization = () => import('./RAMY/checkout/ramy-checkout-shipping-optimization');
export const RAMYProductListingDynamicProductList = () =>
  import('./RAMY/product-listing/ramy-product-listing-dynamic-product-list');
export const RAMYProductResultPrice = () => import('./RAMY/product-listing/ramy-product-result-price');
export const RAMYProfileDeleteSavedCard = () => import('./RAMY/profile/ramy-profile-delete-saved-card');
export const RAMYProfileShippingInformation = () => import('./RAMY/profile/ramy-profile-shipping-information');
export const RAMYErrorFooter = () => import('./RAMY/footer/ramy-error-footer');
export const RAMYFooterLinksList = () => import('./RAMY/footer/ramy-footer-links-list');
export const RAMYProfileOrderSummary = () => import('./RAMY/profile/ramy-profile-order-summary');
export const RAMYProfileOrderHistory = () => import('./RAMY/profile/ramy-profile-order-history');
export const RAMYFooterPayments = () => import('./RAMY/footer/ramy-footer-payments/index.js');

export const TRXAccountTypeSelectorContainer = () =>
  import('./TRX/profile/trx-accounts-contacts-registration-container');
export const TRXAccountsContactsRegistrationContainer = () =>
  import('./TRX/profile/trx-accounts-contacts-registration-container');
export const TRXAccountsContactsRegistration = () => import('./TRX/profile/trx-accounts-contacts-registration');
export const TRXCartContainer = () => import('./TRX/cart/trx-cart-container');
export const TRXCartCouponContainer = () => import('./TRX/cart/trx-cart-coupon-container');
export const TRXCartItemDetails = () => import('./TRX/cart/trx-cart-item-details');
export const TRXCartOrderSummary = () => import('./TRX/cart/trx-cart-order-summary');
export const TRXCartPromotionCodeEntry = () => import('./TRX/cart/trx-cart-promotion-code-entry');
export const TRXCartPromotionsDisplay = () => import('./TRX/cart/trx-cart-promotions-display');
export const TRXCartResumeContainer = () => import('./TRX/cart/trx-cart-resume-container');
export const TRXCategoryContentContainer = () => import('./TRX/product-listing/trx-category-content-container');
export const TRXCategoryLongDescription = () => import('./TRX/product-listing/trx-category-long-description');
export const TRXCategorySearchTermsSummary = () => import('./TRX/category/trx-category-search-terms-summary');
export const TRXCheckoutBackToPreviousButton = () => import('./TRX/checkout/trx-checkout-back-to-previous-button');
export const TRXCheckoutContinueToReviewOrderButton = () =>
  import('./TRX/checkout/trx-checkout-continue-to-review-order-button');
export const TRXCheckoutButton = () => import('./TRX/checkout/trx-checkout-button');
export const TRXCheckoutNavigationContainer = () => import('./TRX/checkout/trx-checkout-navigation-container');
export const TRXCheckoutOrderConfirmation = () => import('./TRX/checkout/trx-checkout-order-confirmation');
export const TRXCheckoutOrderConfirmationContainer = () =>
  import('./TRX/checkout/trx-checkout-order-confirmation-container');
export const TRXCheckoutPaymentMethodsContainer = () => import('./TRX/checkout/trx-checkout-payment-methods-container');
export const TRXCheckoutPlaceOrderButton = () => import('./TRX/checkout/trx-checkout-place-order-button');
export const TRXCheckoutProgressTracker = () => import('./TRX/checkout/trx-checkout-progress-tracker');
export const TRXCheckoutRegistration = () => import('./TRX/checkout/trx-checkout-registration');
export const TRXCheckoutReviewOrderContainer = () => import('./TRX/checkout/trx-checkout-review-order-container');
export const TRXCheckoutShippingContainer = () => import('./TRX/checkout/trx-checkout-shipping-container');
export const TRXCheckoutShippingInformation = () => import('./TRX/checkout/trx-checkout-shipping-information');
export const TRXCheckoutSingleShippingDetails = () => import('./TRX/checkout/trx-checkout-single-shipping-details');
export const TRXCheckoutLogin = () => import('./TRX/checkout/trx-checkout-login');
export const TRXCheckoutPayInStore = () => import('./TRX/checkout/trx-checkout-pay-in-store');
export const TRXCommonAlignComponents = () => import('./TRX/common/trx-common-align-components');
export const TRXCommonContainer = () => import('./TRX/common/trx-common-container');
export const TRXCommonDynamicBreadcrumbs = () => import('./TRX/common/trx-common-dynamic-breadcrumbs');
export const TRXCommonErro500 = () => import('./TRX/common/trx-common-erro-500');
export const TRXCommonError404 = () => import('./TRX/common/trx-common-error-404');
export const TRXCommonImage = () => import('./TRX/common/trx-common-image');
export const TRXCommonImageCarousel = () => import('./TRX/common/trx-common-image-carousel');
export const TRXCommonProductRecommendationsCarousel = () =>
  import('./TRX/common/trx-common-product-recommendations-carousel');
export const TRXCommonLogo = () => import('./TRX/common/trx-common-logo');
export const TRXCommonSearchBoxDesktop = () => import('./TRX/common/trx-common-search-box-desktop');
export const TRXCommonSearchBoxMobile = () => import('./TRX/common/trx-common-search-box-mobile');
export const TRXCommonShowMoreProductsButton = () => import('./TRX/common/trx-common-show-more-products-button');
export const TRXCommonNotifications = () => import('./TRX/common/trx-common-notifications');
export const TRXContinueShoppingButton = () => import('./TRX/common/trx-continue-shopping-button');
export const TRXFooter = () => import('./TRX/footer/trx-footer');
export const TRXFooterBottom = () => import('./TRX/footer/trx-footer-bottom');
export const TRXFooterContact = () => import('./TRX/footer/trx-footer-contact');
export const TRXFooterContainerLinks = () => import('./TRX/footer/trx-footer-container-links');
export const TRXFooterLinksList = () => import('./TRX/footer/trx-footer-links-list');
export const TRXFooterSeparator = () => import('./TRX/footer/trx-footer-separator');
export const TRXHeaderCollectionNavigation = () => import('./TRX/header/trx-header-collection-navigation');
export const TRXHeaderCollectionNavigationDesktop = () =>
  import('./TRX/header/trx-header-collection-navigation-desktop');
export const TRXHeaderAccountPicker = () => import('./TRX/header/trx-header-account-picker');
export const TRXHeaderContainerDesktop = () => import('./TRX/header/trx-header-container/desktop');
export const TRXHeaderContainerMobile = () => import('./TRX/header/trx-header-container/mobile');
export const TRXHeaderIconsContainer = () => import('./TRX/header/trx-header-icons-container');
export const TRXHeaderMenuMobile = () => import('./TRX/header/trx-header-menu-mobile');
export const TRXHeaderMiniCartDesktop = () => import('./TRX/header/trx-header-mini-cart/desktop');
export const TRXHeaderMiniCartMobile = () => import('./TRX/header/trx-header-mini-cart/mobile');
export const TRXHeaderUserProfileLinksDesktop = () => import('./TRX/header/trx-header-user-profile-links/desktop');
export const TRXHeaderUserProfileLinksMobile = () => import('./TRX/header/trx-header-user-profile-links/mobile');
export const TRXLogin = () => import('./TRX/login/trx-login');
export const TRXLoginContainer = () => import('./TRX/login/trx-login-container');
export const TRXCommonNavigationBreadcrumbs = () => import('./TRX/common/trx-common-navigation-breadcrumbs');
export const TRXProductAddToCartButton = () => import('./TRX/product/trx-product-add-to-cart-button');
export const TRXProductAddToWishlist = () => import('./TRX/product/trx-product-add-to-wishlist');
export const TRXProductBreadcrumbs = () => import('./TRX/product/trx-product-breadcrumbs');
export const TRXProductInventoryStatus = () => import('./TRX/product/trx-product-inventory-status');
export const TRXProductListingDynamicProductList = () =>
  import('./TRX/product-listing/trx-product-listing-dynamic-product-list');
export const TRXProductListingDynamicProductListingContainer = () =>
  import('./TRX/product-listing/trx-product-listing-dynamic-product-listing-container');
export const TRXProductListingFacetedNavigation = () =>
  import('./TRX/product-listing/trx-product-listing-faceted-navigation');
export const TRXProductListingProductListingSummaryInformation = () =>
  import('./TRX/product-listing/trx-product-listing-product-listing-summary-information');
export const TRXProductListingProductResultColorSwatches = () =>
  import('./TRX/product-listing/trx-product-listing-product-result-color-swatches');
export const TRXProductListingProductResultImage = () =>
  import('./TRX/product-listing/trx-product-listing-product-result-image');
export const TRXProductListingProductResultImageQuickView = () =>
  import('./TRX/product-listing/trx-product-listing-product-result-image-quick-view');
export const TRXProductListingProductResultName = () =>
  import('./TRX/product-listing/trx-product-listing-product-result-name');
export const TRXProductListingSortResults = () => import('./TRX/product-listing/trx-product-listing-sort-results');
export const TRXProductName = () => import('./TRX/product/trx-product-name');
export const TRXProductPrice = () => import('./TRX/product/trx-product-price');
export const TRXProductProductDetailsContainer = () => import('./TRX/product/trx-product-product-details-container');
export const TRXProductProductImageViewer = () => import('./TRX/product/trx-product-product-image-viewer');
export const TRXProductProductVariantOptions = () => import('./TRX/product/trx-product-product-variant-options');
export const TRXProductResultPrice = () => import('./TRX/product-listing/trx-product-result-price');
export const TRXProductListingFacetedNavigationMobile = () =>
  import('./TRX/product-listing/trx-product-listing-faceted-navigation/mobile');
export const TRXProductListingFacetedNavigationMobileContainer = () =>
  import('./TRX/product-listing/trx-product-listing-faceted-navigation-mobile-container');
export const TRXProfileAccountAddressBook = () => import('./TRX/profile/trx-profile-account-address-book');
export const TRXProfileAddressBook = () => import('./TRX/profile/trx-profile-address-book');
export const TRXProfileAddressBookSummary = () => import('./TRX/profile/trx-profile-address-book-summary');
export const TRXProfileAddressForm = () => import('./TRX/profile/trx-profile-address-form');
export const TRXProfileContainer = () => import('./TRX/profile/trx-profile-container');
export const TRXProfileContainerSection = () => import('./TRX/profile/trx-profile-container-section');
export const TRXProfileDetailsSummary = () => import('./TRX/profile/trx-profile-details-summary');
export const TRXProfileEmailMarketingPreferencesSummary = () =>
  import('./TRX/profile/trx-profile-email-marketing-preferences-summary');
export const TRXProfileNavigationBreadCrumbs = () => import('./TRX/profile/trx-profile-navigation-bread-crumbs');
export const TRXProfilePurchaseLists = () => import('./TRX/profile/trx-profile-purchase-lists');
export const TRXProfilePurchaseListDetailsContainer = () =>
  import('./TRX/profile/trx-profile-purchase-list-details-container');
export const TRXProfileRecentOrders = () => import('./TRX/profile/trx-profile-recent-orders');
export const TRXProfileResetPassword = () => import('./TRX/profile/trx-profile-reset-password');
export const TRXProfileSavedCardSummary = () => import('./TRX/profile/trx-profile-saved-card-summary');
export const TRXProfileShippingInformation = () => import('./TRX/profile/trx-profile-shipping-information');
export const TRXProfileUpdatePassword = () => import('./TRX/profile/trx-profile-update-password');
export const TRXProfileUpdateExpiredPassword = () => import('./TRX/profile/trx-profile-update-expired-password');
export const TRXProfileSavedCards = () => import('./TRX/profile/trx-profile-saved-cards');
export const TRXProfileDetails = () => import('./TRX/profile/trx-profile-details');
export const TRXProfileCreatePurchaseList = () => import('./TRX/profile/trx-profile-create-purchase-list');
export const TRXProfilePurchaseListsSummary = () => import('./TRX/profile/trx-profile-purchase-lists-summary');
export const TRXProfilePurchaseListSummaryInformation = () =>
  import('./TRX/profile/trx-profile-purchase-list-summary-information');
export const TRXProfilePurchaseListActionButtons = () =>
  import('./TRX/profile/trx-profile-purchase-list-action-buttons');
export const TRXProfilePurchaseListInformation = () => import('./TRX/profile/trx-profile-purchase-list-information');
export const TRXProfileWelcome = () => import('./TRX/profile/trx-profile-welcome');
export const TRXProfileEmailMarketingPreferences = () =>
  import('./TRX/profile/trx-profile-email-marketing-preferences');
export const TRXProfileEmailMarketingPreferencesContainer = () =>
  import('./TRX/profile/trx-profile-email-marketing-preferences-container');
export const TRXProfileAccountDetails = () => import('./TRX/profile/trx-profile-account-details');
export const TRXProfileOrderDetailsContainer = () => import('./TRX/profile/trx-profile-order-details-container');
export const TRXProfileOrderInformation = () => import('./TRX/profile/trx-profile-order-information');
export const TRXProfileReturnsSummaryContainer = () => import('./TRX/profile/trx-profile-returns-summary-container');
export const TRXProfilePaymentInformation = () => import('./TRX/profile/trx-profile-payment-information');
export const TRXProfileOrderSummary = () => import('./TRX/profile/trx-profile-order-summary');
export const TRXProfileOrderActions = () => import('./TRX/profile/trx-profile-order-actions');
export const TRXProfileReturnRequestId = () => import('./TRX/profile/trx-profile-return-request-id');
export const TRXProfileReturnDetailsLink = () => import('./TRX/profile/trx-profile-return-details-link');
export const TRXProfileReturnRequestItems = () => import('./TRX/profile/trx-profile-return-request-items');
export const TRXProfileRegistration = () => import('./TRX/profile/trx-profile-registration');
export const TRXProfileRegistrationContainer = () => import('./TRX/profile/trx-profile-registration-container');
export const TRXHeaderTopBar = () => import('./TRX/header/trx-header-top-bar');
export const RAMYCartContainer = () => import('./RAMY/cart/ramy-cart-container');
export const RAMYCommonCookieControl = () => import('./RAMY/common/ramy-common-cookie-control');
export const RAMYCommonSearchBoxDesktop = () => import('./RAMY/common/ramy-common-search-box-desktop');
export const RAMYCommonSearchBoxMobile = () => import('./RAMY/common/ramy-common-search-box-mobile');
export const TRXProfileContactRegistrationRequests = () =>
  import('./TRX/profile/trx-profile-contact-registration-requests');
export const RAMYProfileDetails = () => import('./RAMY/profile/ramy-profile-details');
export const RAMYCommonNavigationBreadcrumbs = () => import('./RAMY/common/ramy-common-navigation-breadcrumbs');
export const RAMYProfileAccountDetails = () => import('./RAMY/profile/ramy-profile-account-details');
export const RAMYProfileSavedCards = () => import('./RAMY/profile/ramy-profile-saved-cards');
export const RAMYProfileAddCreditCard = () => import('./RAMY/profile/ramy-profile-add-credit-card');
export const RAMYProductListingSummaryInformation = () =>
  import('./RAMY/product-listing/ramy-product-listing-summary-information');
export const RAMYProductListingSummaryInformationFooter = () =>
  import('./RAMY/product-listing/ramy-product-listing-summary-information-footer');
export const RAMYProfileUpdatePassword = () => import('./RAMY/profile/ramy-profile-update-password');
export const RAMYHeaderMiniCartDesktop = () => import('./RAMY/header/ramy-header-mini-cart/desktop');
export const RAMYHeaderMiniCartMobile = () => import('./RAMY/header/ramy-header-mini-cart/mobile');
export const RAMYRequestDataChange = () => import('./RAMY/profile/ramy-request-data-change');
export const RAMYCommonTalkChat = () => import('./RAMY/common/ramy-common-talk-chat');
export const RAMYProductRecommendationsCarouselNotLogged = () =>
  import('./RAMY/common/ramy-common-product-recommendations-carousel-not-logged');
export const RAMYCheckoutButton = () => import('./RAMY/checkout/ramy-checkout-button');
export const RAMYProductListingProductResultName = () =>
  import('./RAMY/product-listing/ramy-product-listing-product-result-name');
export const RAMYCheckoutPlaceOrderButton = () => import('./RAMY/checkout/ramy-checkout-place-order-button');
export const RAMYProfilePaymentInformation = () => import('./RAMY/profile/ramy-profile-payment-information');
export const RAMYCheckoutHeaderContainer = () => import('./RAMY/checkout/ramy-checkout-header-container/index.js');
export const RAMYProfileRecentOrders = () => import('./RAMY/profile/ramy-profile-recent-orders');
export const FUGINIAccountsContactsRegistrationContainer = () =>
  import('./FUGINI/profile/fugini-accounts-contacts-registration-container');
export const FUGINIAccountsContactsRegistration = () =>
  import('./FUGINI/profile/fugini-accounts-contacts-registration');
export const FUGINILogin = () => import('./FUGINI/login/fugini-login');
export const FUGINILoginContainer = () => import('./FUGINI/login/fugini-login-container');
export const FUGINIProfileResetPassword = () => import('./FUGINI/profile/fugini-profile-reset-password');
export const FUGINIProfileDetails = () => import('./FUGINI/profile/fugini-profile-details');
export const FUGINICommonProductRecommendationsCarousel = () =>
  import('./FUGINI/common/fugini-common-product-recommendations-carousel');
export const FUGINICommonImage = () => import('./FUGINI/common/fugini-common-image');
export const FUGINICommonCookieControl = () => import('./FUGINI/common/fugini-common-cookie-control');
export const FUGINIProductListingFacetedNavigationMobile = () =>
  import('./FUGINI/product-listing/fugini-product-listing-faceted-navigation/mobile');
export const FUGINIProductListingFacetedNavMobileContainer = () =>
  import('./FUGINI/product-listing/fugini-product-listing-faceted-nav-mobile-container');
export const FUGINICommonNavigationBreadcrumbs = () => import('./FUGINI/common/fugini-common-navigation-breadcrumbs');
export const FUGINIProfileCreatePurchaseList = () => import('./FUGINI/profile/fugini-profile-create-purchase-list');
export const FUGINIProfilePurchaseListInformation = () =>
  import('./FUGINI/profile/fugini-profile-purchase-list-information');
export const FUGINICheckoutOrderConfirmation = () => import('./FUGINI/checkout/fugini-checkout-order-confirmation');
export const FUGINIContinueShoppingButton = () => import('./FUGINI/common/fugini-continue-shopping-button');
export const FUGINIProductListingFacetedNavigation = () =>
  import('./FUGINI/product-listing/fugini-product-listing-faceted-navigation');
export const RAMYFooterBottom = () => import('./RAMY/footer/ramy-footer-bottom');
export const FUGINIProfileRepresentativeSales = () => import('./FUGINI/profile/fugini-profile-representative-sales');
export const FUGINIHeaderUserProfileLinksDesktop = () =>
  import('./FUGINI/header/fugini-header-user-profile-links/desktop');
export const FUGINIHeaderUserProfileLinksMobile = () =>
  import('./FUGINI/header/fugini-header-user-profile-links/mobile');
export const FUGINIHeaderAccountPicker = () => import('./FUGINI/header/fugini-header-account-picker');
export const FUGINIProfileAddCreditCard = () => import('./FUGINI/profile/fugini-profile-add-credit-card');
export const FUGINICommonSearchBoxDesktop = () => import('./FUGINI/common/fugini-common-search-box-desktop');
export const FUGINIHeaderContainerMobile = () => import('./FUGINI/header/fugini-header-container/mobile');
export const FUGINIProfileDetailsSummary = () => import('./FUGINI/profile/fugini-profile-details-summary');
export const FUGINIProfileEmailMarketingPreferencesSummary = () =>
  import('./FUGINI/profile/fugini-profile-email-marketing-preferences-summary');
export const FUGINIProfileDeleteSavedCard = () => import('./FUGINI/profile/fugini-profile-delete-saved-card');
export const FUGINICheckoutReviewOrderPaymentInformation = () =>
  import('./FUGINI/checkout/fugini-checkout-review-order-payment-information');
export const FUGINIProfileOrderDetailsContainer = () =>
  import('./FUGINI/profile/fugini-profile-order-details-container');
export const FUGINIProfileOrderHistory = () => import('./FUGINI/profile/fugini-profile-order-history');
export const FUGINIProfileOrderInformation = () => import('./FUGINI/profile/fugini-profile-order-information');
export const FUGINIProfileOrderSummary = () => import('./FUGINI/profile/fugini-profile-order-summary');
export const FUGINIProfilePaymentInformation = () => import('./FUGINI/profile/fugini-profile-payment-information');
export const FUGINIProfileShippingInformation = () => import('./FUGINI/profile/fugini-profile-shipping-information');
export const FUGINIProfilePurchaseListActionButtons = () =>
  import('./FUGINI/profile/fugini-profile-purchase-list-action-buttons');
export const FUGINICategorySearchTermsSummary = () => import('./FUGINI/category/fugini-category-search-terms-summary');
export const FUGINIFooterLinksList = () => import('./FUGINI/footer/fugini-footer-links-list');
